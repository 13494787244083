import { useWeb3React } from "@web3-react/core";
import React from "react";
import Button from "../../../components/Button";
import useStakeDetails from "../../../hooks/useStakeDetails";
import { redeemDeposit } from "../../../web3/helpers";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
const Token = () => {
  const { rpBalance } = useStakeDetails();
  const { library, account } = useWeb3React();
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.1, duration: 0.5 }}
      className="card"
    >
      <div className="flex justify-between ">
        <p className="text-[32px] font-semibold">Tokens</p>
        <div>
          <p className="text-right text-xl font-medium">{rpBalance}</p>
          <span className="text-sm text-[#888682]"> Total RP tokens</span>
        </div>
      </div>
      <p className="text-lg max-w-[450px] text-muted  leading-snug my-4">
        RP tokens are your proof of share in the smart contract, you can get
        your principal back by redeeming these tokens
      </p>
      <br />
      <Button
        color="primary"
        onClick={() => {
          if (!account) return toast.error("Connect Wallet");
          redeemDeposit(library);
        }}
      >
        <p>Redeem Tokens</p>
      </Button>
      <p className="text-muted mt-5">
        Note: You can only redeem tokens for the deposit after the locking
        period.
      </p>
    </motion.div>
  );
};

export default Token;
