import React from "react";
import Layout from "../../components/Layout";
import Claim from "./sections/Claim";
import Deposit from "./sections/Deposit";
import Referral from "./sections/Referral";
import Roi from "./sections/Roi";
import Token from "./sections/Token";
import Withdraw from "./sections/Withdraw";

const Home = () => {
  return (
    <div className="relative pb-4">
      <Layout>
        <div className="container mt-10">
          <h1 className="text-[48px] ">Welcome</h1>
          <p className="text-lg max-w-[800px] font-light">
            This portal helps you to do all your transactions with Safe Haven in
            a secure and easy way. Come on let’s make your investments
            recession-proof !!
          </p>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-6">
            <Deposit />
            <Token />
            <Claim />
            <Withdraw />
            <div className=" md:col-span-2 grid gap-6">
              <Referral />
              <Roi />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Home;
