import React from "react";
import { motion } from "framer-motion";
import useStakeDetails from "../../../hooks/useStakeDetails";
const Roi = () => {
  const { roi } = useStakeDetails();
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.1, duration: 0.5 }}
      className="card flex md:flex-row flex-col md:justify-between md:items-center"
    >
      <div className="">
        <h3 className="text-3xl font-bold">Current ROI percentage</h3>
        <p className="text-muted mt-5">
          It is a long established fact that a reader will be distracted by the
          readable content.
        </p>
      </div>
      <div className="bg-white text-black p-4 max-w-max mt-2 md:mt-0 rounded-xl">
        <p className="text-xl font-bold text-center">{roi}%</p>
        <p className="text-sm">Percentage</p>
      </div>
    </motion.div>
  );
};

export default Roi;
