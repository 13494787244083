import { useWeb3React } from "@web3-react/core";
import React from "react";
import toast from "react-hot-toast";
import Button from "../../../components/Button";
import Timer from "../../../components/Timer";
import useStakeDetails from "../../../hooks/useStakeDetails";
import { getCurrentUnix } from "../../../utils";
import { reInvest, withdraw } from "../../../web3/helpers";
import { motion } from "framer-motion";
const Withdraw = () => {
  const { approvedWithdrawal, weeklyTime } = useStakeDetails();
  const { library, account } = useWeb3React();
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.1, duration: 0.5 }}
      className="card"
    >
      <div className="flex flex-col md:flex-row  md:justify-between ">
        <p className="text-[32px] font-semibold">Withdraw</p>
        <div>
          <p className="md:text-right text-xl font-medium">
            {approvedWithdrawal} BUSD
          </p>
          <p className="text-sm text-[#888682] md:text-right">
            {" "}
            Available Withdrawal 50% Allowed
          </p>

          <div className="text-right">
            <Timer timestamp={weeklyTime.deadline} />
          </div>
        </div>
      </div>
      <p className="text-lg max-w-[400px] text-muted  leading-snug my-4">
        To withdraw your earnings or to reinvest click on below buttons
      </p>
      <br />
      <div className="grid md:grid-flow-col gap-2 md:justify-start">
        <Button
          color="primary"
          onClick={() => {
            if (!account) return toast.error("Connect Wallet");
            if (!approvedWithdrawal)
              return toast.error("You dont have earnings to Withdraw");
            const unix = getCurrentUnix();
            if (Number(weeklyTime.deadline) > unix) {
              console.log(unix, weeklyTime.deadline);
              toast.error("Claim is not allowed before deadline is reached");
              return;
            }
            withdraw(library);
          }}
        >
          Withdraw
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            if (!account) return toast.error("Connect Wallet");
            if (!approvedWithdrawal)
              return toast.error("You dont have earnings to Withdraw");
            const unix = getCurrentUnix();
            if (Number(weeklyTime.deadline) > unix) {
              console.log(unix, weeklyTime.deadline);
              toast.error("Claim is not allowed before deadline is reached");
              return;
            }
            reInvest(library);
          }}
        >
          Re-Invest
        </Button>
      </div>
      <p className="text-muted mt-5">
        Note: You can choose to withdraw your earnings available for you or
        re-invest them in to the program and get more returns.
      </p>
    </motion.div>
  );
};

export default Withdraw;
