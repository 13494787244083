import * as dayjs from "dayjs";

import { useEffect, useState } from "react";

const useTimer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const difference =
    +dayjs(`2022-11-28T12:00:00.000+0000`, "YYYY-MM-DDTHH:mm:ss.000ZZ") -
    +new Date();
  useEffect(() => {
    const id = setTimeout(() => {
      if (difference > 0) {
        setDays(Math.floor(difference / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((difference / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((difference / 1000 / 60) % 60));
        setSeconds(Math.floor((difference / 1000) % 60));
      }
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });
  // const difference = 1000;
  return {
    difference,
    days,
    hours,
    minutes,
    seconds,
  };
};

export default useTimer;
