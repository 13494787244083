import React, { useState } from "react";
import Home from "./screens/Home";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";

import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import About from "./screens/About";
import TimerModal from "./components/TimerModal";
dayjs.extend(utc);
const App = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className=" min-h-screen text-white">
      <Toaster containerClassName="text-sm" />
      <Routes>
        <Route index element={<About setOpen={setOpen} />} />
        <Route path="app/:id" element={<Home />} />
        <Route path="/app" element={<Home />} />
      </Routes>

      <TimerModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default App;
