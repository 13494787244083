import toast from "react-hot-toast";
import Web3 from "web3";
import { contractAbi, contractAddress } from "../contracts/primary";
import { busdAbi, busdAddress } from "../contracts/busd";
import { DEFAULT_REF } from "../../utils";
import { rpAddress } from "../contracts/rp";

export const shortAddress = (str) => {
  if (str) {
    if (str.length < 10) return str;
    return `${str.slice(0, 4)}...${str.slice(-4)}`;
  } else {
    return "";
  }
};

export const toHex = (num) => {
  const val = Number(num);
  return `0x${val.toString(16)}`;
};

export const formatFromWei = (str) => {
  if (str) {
    if (str.length < 1) return str;
    return Web3.utils.fromWei(`${str}`, "ether");
  }
};
export const formatToWei = (str) => {
  if (str) {
    if (str.length < 1) return str;
    return Web3.utils.toWei(str, "ether");
  }
};

export const getContract = async (library, token) => {
  try {
    let _contract = null;
    if (token) {
      _contract = await new library.eth.Contract(busdAbi, token);
    } else {
      _contract = await new library.eth.Contract(contractAbi, contractAddress);
    }
    return { ok: true, contract: _contract };
  } catch (error) {
    console.log(error);
    return { ok: false, contract: null };
  }
};

export const getUserBalance = async (library, account) => {
  try {
    let balance = await library.eth.getBalance(account);
    balance = formatFromWei(balance);

    return Number(balance).toFixed(4);
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const approve = async (library, amount) => {
  const { ok, contract: tokenContract } = await getContract(
    library,
    busdAddress
  );

  if (!ok) {
    toast.error("Something went wrong!");
    return;
  }

  if (!amount || Number(amount) < 50) {
    toast.error("amount should be minimum 50");
    return;
  }
  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  let balance = await tokenContract.methods.balanceOf(accounts[0]).call();
  balance = formatFromWei(balance);

  if (+balance <= 0) {
    toast.error("You dont have sufficient balance.", { id: toastId });
    return;
  }

  const finalAmount = formatToWei(amount);

  toast.loading("Please Allow our protocal to use your token", { id: toastId });
  tokenContract.methods
    .approve(contractAddress, finalAmount)
    .send({
      from: accounts[0],
    })
    .on("transactionHash", function () {
      toast.loading(
        "Please wait to get confirmation of the transaction from blockchain",
        { id: toastId }
      );
    })
    .on("confirmation", function (receipt) {
      console.log("CONFIRMATION", receipt);
    })
    .on("receipt", async function (receipt) {
      toast.success("Transaction has been confirmed", { id: toastId });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    })
    .on("error", function (error, receipt) {
      if (error.code === 4001) {
        toast.error("Transaction Rejected", { id: toastId });
      } else {
        toast.error("Transaction Failed", { id: toastId });
      }
    });
};

export const redeemDeposit = async (library) => {
  const { ok, contract } = await getContract(library);
  const { ok: rpOk, contract: rpContract } = await getContract(
    library,
    rpAddress
  );

  if (!ok || !rpOk) {
    toast.error("Something went wrong!");
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  let balance = await rpContract.methods.balanceOf(accounts[0]).call();
  balance = Number(formatFromWei(balance));

  if (balance <= 0) {
    toast.error("You dont have sufficient balance.", { id: toastId });
    return;
  }

  const allowance = await rpContract.methods
    .allowance(accounts[0], contractAddress)
    .call();
  const totalSupply = await rpContract.methods.totalSupply().call();

  if (+allowance > 0) {
    toast.loading("Please Confirm Transaction..", { id: toastId });

    try {
      contract.methods
        .redeemDeposit()
        .send({
          from: accounts[0],
        })
        .on("transactionHash", function () {
          toast.loading(
            "Please wait to get confirmation of the transaction from blockchain",
            { id: toastId }
          );
        })
        .on("confirmation", function (receipt) {
          console.log("CONFIRMATION", receipt);
        })
        .on("receipt", async function (receipt) {
          toast.success("Transaction Completed Successfully", { id: toastId });

          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        })
        .on("error", function (error, receipt) {
          if (error.code === 4001) {
            toast.error("Transaction Rejected", { id: toastId });
          } else {
            toast.error("Transaction Failed", { id: toastId });
          }
        });
    } catch (error) {
      toast.error("Something went wrong!", { id: toastId });
    }
    return;
  }

  toast.loading("Please Allow our protocal to use your token", { id: toastId });
  try {
    rpContract.methods
      .approve(contractAddress, totalSupply)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.loading("Please confirm transaction to deposit", { id: toastId });

        contract.methods
          .redeemDeposit()
          .send({
            from: accounts[0],
          })
          .on("transactionHash", function () {
            toast.loading(
              "Please wait to get confirmation of the transaction from blockchain",
              { id: toastId }
            );
          })
          .on("confirmation", function (receipt) {
            console.log("CONFIRMATION", receipt);
          })
          .on("receipt", async function (receipt) {
            toast.success("Transaction Completed Successfully", {
              id: toastId,
            });

            setTimeout(() => {
              window.location.reload(false);
            }, 1000);
          })
          .on("error", function (error, receipt) {
            if (error.code === 4001) {
              toast.error("Transaction Rejected", { id: toastId });
            } else {
              toast.error("Transaction Failed", { id: toastId });
            }
          });
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    toast.error("Something went wrong!", { id: toastId });
  }
};

export const withdraw = async (library) => {
  const { ok, contract } = await getContract(library);

  if (!ok) {
    toast.error("Something went wrong!");
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  toast.loading("Please Confirm Transaction..", { id: toastId });

  contract.methods
    .withdrawal(true)
    .send({
      from: accounts[0],
    })
    .on("transactionHash", function () {
      toast.loading(
        "Please wait to get confirmation of the transaction from blockchain",
        { id: toastId }
      );
    })
    .on("confirmation", function (receipt) {
      console.log("CONFIRMATION", receipt);
    })
    .on("receipt", async function (receipt) {
      toast.success("Transaction Completed Successfully", { id: toastId });

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    })
    .on("error", function (error, receipt) {
      if (error.code === 4001) {
        toast.error("Transaction Rejected", { id: toastId });
      } else {
        toast.error("Transaction Failed", { id: toastId });
      }
    });
};

export const claimDailyRewards = async (library) => {
  const { ok, contract } = await getContract(library);

  if (!ok) {
    toast.error("Connect wallet");
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  toast.loading("Please Confirm Transaction..", { id: toastId });
  contract.methods
    .claimDailyRewards()
    .send({
      from: accounts[0],
    })
    .on("transactionHash", function () {
      toast.loading(
        "Please wait to get confirmation of the transaction from blockchain",
        { id: toastId }
      );
    })
    .on("confirmation", function (receipt) {
      console.log("CONFIRMATION", receipt);
    })
    .on("receipt", async function (receipt) {
      toast.success("Transaction Completed Successfully", { id: toastId });

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    })
    .on("error", function (error, receipt) {
      if (error.code === 4001) {
        toast.error("Transaction Rejected", { id: toastId });
      } else {
        toast.error("Transaction Failed", { id: toastId });
      }
    });
};

export const refWithdraw = async (library) => {
  const { ok, contract } = await getContract(library);

  if (!ok) {
    toast.error(
      "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"
    );
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  // const isAllready = await contract.methods
  //   .checkAlready()
  //   .call({ from: accounts[0] });

  // // console.log(isAllready);
  // if (!isAllready) {
  //   return toast.error(
  //     "You don't have active deposits. Deposit first to claim referral",
  //     { id: toastId }
  //   );
  // }

  toast.loading("Please Confirm Transaction..", { id: toastId });
  contract.methods
    .referralWithdraw()
    .send({
      from: accounts[0],
    })
    .on("transactionHash", function () {
      toast.loading(
        "Please wait to get confirmation of the transaction from blockchain",
        { id: toastId }
      );
    })
    .on("confirmation", function (receipt) {
      console.log("CONFIRMATION", receipt);
    })
    .on("receipt", async function (receipt) {
      toast.success("Transaction Completed Successfully", { id: toastId });

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    })
    .on("error", function (error, receipt) {
      if (error.code === 4001) {
        toast.error("Transaction Rejected", { id: toastId });
      } else {
        toast.error("Transaction Failed", { id: toastId });
      }
    });
};

export const reInvest = async (library) => {
  const { ok, contract } = await getContract(library);

  if (!ok) {
    toast.error("Something went wrong!");
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  toast.loading("Please Confirm Transaction..", { id: toastId });

  contract.methods
    .withdrawal(false)
    .send({
      from: accounts[0],
    })
    .on("transactionHash", function () {
      toast.loading(
        "Please wait to get confirmation of the transaction from blockchain",
        { id: toastId }
      );
    })
    .on("confirmation", function (receipt) {
      console.log("CONFIRMATION", receipt);
    })
    .on("receipt", async function (receipt) {
      toast.success("Transaction Completed Successfully", { id: toastId });

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    })
    .on("error", function (error, receipt) {
      if (error.code === 4001) {
        toast.error("Transaction Rejected", { id: toastId });
      } else {
        toast.error("Transaction Failed", { id: toastId });
      }
    });
};

export const approveAndDeposit = async (library, input, ref) => {
  const { ok, contract: tokenContract } = await getContract(
    library,
    busdAddress
  );
  const { ok: contractOK, contract } = await getContract(library);

  if (!ok || !contractOK) {
    toast.error("Something went wrong, please try again later");
    return;
  }
  if (!input || Number(input) < 50) {
    toast.error("Minimum Stake amount is 50 BUSD.");
    return;
  }
  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  let balance = await tokenContract.methods.balanceOf(accounts[0]).call();
  balance = formatFromWei(balance);
  console.log("BALANCE", balance);

  if (+balance < input || +balance < 50) {
    toast.error("You dont have sufficient balance.", { id: toastId });
    return;
  }
  const allowance = await tokenContract.methods
    .allowance(accounts[0], contractAddress)
    .call();
  const totalSupply = await tokenContract.methods.totalSupply().call();
  console.log(allowance, totalSupply);

  // const owner = await contract.methods.owner().call();

  const refAddres = ref || DEFAULT_REF;

  // return;
  if (+allowance > 0) {
    try {
      contract.methods
        .deposit(refAddres, Web3.utils.toWei(`${input}`, "ether"))
        .send({
          from: accounts[0],
        })
        .on("transactionHash", function () {
          toast.loading(
            "Please wait to get confirmation of the transaction from blockchain",
            { id: toastId }
          );
        })
        .on("confirmation", function (receipt) {
          console.log("CONFIRMATION", receipt);
        })
        .on("receipt", async function (receipt) {
          toast.success("Transaction Completed Successfully", { id: toastId });

          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        })
        .on("error", function (error, receipt) {
          if (error.code === 4001) {
            toast.error("Transaction Rejected", { id: toastId });
          } else {
            toast.error("Transaction Failed", { id: toastId });
          }
        });
    } catch (error) {
      toast.error("Something went wrong!", { id: toastId });
    }
    return;
  }

  toast.loading("Please Allow our protocal to use your token", { id: toastId });
  try {
    tokenContract.methods
      .approve(contractAddress, totalSupply)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.loading("Please confirm transaction to deposit", { id: toastId });

        contract.methods
          .deposit(refAddres, Web3.utils.toWei(input.toString(), "ether"))
          .send({
            from: accounts[0],
          })
          .on("transactionHash", function () {
            toast.loading(
              "Please wait to get confirmation of the transaction from blockchain",
              { id: toastId }
            );
          })
          .on("confirmation", function (receipt) {
            console.log("CONFIRMATION", receipt);
          })
          .on("receipt", async function (receipt) {
            toast.success("Transaction Completed Successfully", {
              id: toastId,
            });

            setTimeout(() => {
              window.location.reload(false);
            }, 1000);
          })
          .on("error", function (error, receipt) {
            if (error.code === 4001) {
              toast.error("Transaction Rejected", { id: toastId });
            } else {
              toast.error("Transaction Failed", { id: toastId });
            }
          });
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    toast.error("Something went wrong!", { id: toastId });
  }
};
