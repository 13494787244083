import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import Button from "../../../components/Button";
import useStakeDetails from "../../../hooks/useStakeDetails";
import { approveAndDeposit } from "../../../web3/helpers";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import useTimer from "../../../hooks/useTimer";
const Deposit = () => {
  const [input, setInput] = useState(50);
  const { investments, allowance } = useStakeDetails();
  const { library, account } = useWeb3React();
  const { id } = useParams();
  const { difference } = useTimer();

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.1, duration: 0.5 }}
      className="card"
    >
      <div className="flex justify-between ">
        <p className="text-[32px] font-semibold">Deposit</p>
        <div>
          <p className="text-right text-xl font-medium">{investments} BUSD</p>
          <span className="text-sm text-[#888682]">Total active deposit</span>
        </div>
      </div>
      <p className="text-lg max-w-[400px] text-muted  leading-snug my-4">
        Make sure you are connected to your wallet before entering the amount
        and approving your deposit
      </p>
      <br />
      <div className="flex flex-col md:flex-row">
        <input
          type="text"
          className="flex-1 rounded-md mb-2 md:mb-0 md:mr-2 p-2 bg-transparent focus:outline-none focus:ring-0 focus:border-primary"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <Button
          color="primary"
          onClick={() => {
            difference > 0
              ? toast.error("Launching soon")
              : account
              ? approveAndDeposit(library, input, id)
              : toast.error("Connect Wallet To Deposit");
          }}
        >
          {allowance ? "Make A Deposit" : "Approve"}
        </Button>
      </div>
      <p className="text-muted mt-5">
        Note: Your deposit will be locked for 30 days.
      </p>
    </motion.div>
  );
};

export default Deposit;
