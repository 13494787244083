import React from "react";
import { motion } from "framer-motion";
import Image from "../../../assets/about/2.png";
const Second = () => {
  const list = [
    "High Unemployment",
    "Low wages and lower incomes",
    "High borrowing costs",
    "Overall economic slowdown",
  ];
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <img src={Image} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <>
          <h2 className="text-3xl">The Impact</h2>
          <p className="text-xl">Recession means</p>
          <ul className="text-sm text-[#ADADAD] mt-4">
            {list.map((val, i) => (
              <li key={i} className="py-2 pl-2 flex items-center">
                <span className="text-primary">●</span>{" "}
                <span className="block ml-2">{val}</span>
              </li>
            ))}
          </ul>
        </>
      </motion.div>
    </>
  );
};

export default Second;
