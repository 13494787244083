import React from "react";
import { motion } from "framer-motion";
import Image1 from "../../../assets/about/5.png";
const Fifth = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <img src={Image1} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <>
          <h2 className="text-3xl">How it Works</h2>
          <p className="text-xl">
            A smart contract that keeps user funds safe and make stable returns
          </p>
          <div className="text-xs">
            <p className="text-sm text-[#ADADAD] mt-4">
              We have created a robust smart contract on Binance Smart Chain
              through which we accept investments from our users. BUSD is chosen
              as the mode of investment to maintain stability, increase speed
              and reduce costs.
            </p>
            <p className="text-sm mt-4 text-primary">
              For each BUSD deposited, the contract creates and transfers
              Recession Proof tokens (RP Tokens) to your wallet at 1:1 ratio.
              These RP tokens are your proof of investment and an instrument to
              get your investment back anytime you want.
            </p>
            <p className="text-sm mt-4 text-primary">
              While 70% of the pool fund will remain in the smart contract to
              maintain liquidity, 30% of the funds will be diverted outwards
              through secure connections for making investments. The percentage
              of fund diversion is fixed (hard coded in the smart contract).
            </p>
            <p className="text-sm text-[#ADADAD] mt-4">
              The funds moving outside of smart contract will be invested into
              various recession proof options and the returns will be
              distributed among the investors as daily returns. There is also a
              secure way for transferring these daily gains into the smart
              contract. Users can withdraw their daily returns from the
              dashboard whenever they want.
            </p>
          </div>
        </>
      </motion.div>
    </>
  );
};

export default Fifth;
