import React from "react";

const Button = ({
  children,
  color,
  disable,
  fullWidth,
  rounded,
  link,
  ...props
}) => {
  // const txtColor = color === "white" ? "text-white" : "text-primary";
  const className = `${
    disable
      ? " bg-gray-500 pointer-events-none "
      : color === "black"
      ? "bg-black text-white"
      : color === "primary"
      ? "bg-primary text-white"
      : color === "secondary"
      ? "bg-secondary text-white"
      : "gradient-primary text-white"
  }  p-2 px-6 ${rounded ? "rounded-full" : " rounded-md"} ${
    fullWidth && "w-full"
  }  capitalize  font-medium min-h-[42px] min-w-[180px] grid grid-flow-col gap-2 justify-center items-center`;
  return (
    <>
      {link ? (
        <a {...props} className={`${className}`}>
          {children}
        </a>
      ) : (
        <button {...props} className={`${className}`}>
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
