import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import util from "../../assets/utils.png";
import util1 from "../../assets/utils1.png";
import util2 from "../../assets/utils2.png";
import util3 from "../../assets/utils3.png";
import util4 from "../../assets/utils4.png";
import Logo from "../../assets/Logo.png";
import First from "./slides/First";
import Second from "./slides/Second";
import Third from "./slides/Third";
import Fourth from "./slides/Fourth";
import Fifth from "./slides/Fifth";
import Sixth from "./slides/Sixth";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";

import { Link } from "react-router-dom";
import useTimer from "../../hooks/useTimer";
const About = ({ setOpen }) => {
  const [currentAcctive, setCurrentActive] = useState(0);
  const { difference } = useTimer();
  const list = [
    "The Warnings",
    "The Impact",
    " The Big Questions",
    "The Answer",
    "How it Works",
    "The Numbers",
  ];

  const pages = {
    0: <First />,
    1: <Second />,
    2: <Third />,
    3: <Fourth />,
    4: <Fifth />,
    5: <Sixth setOpen={setOpen} />,
  };

  const increment = () => {
    setCurrentActive((prev) => (prev !== list.length - 1 ? prev + 1 : prev));
  };
  const decrement = () => {
    setCurrentActive((prev) => (prev > 0 ? prev - 1 : prev));
  };

  return (
    <div className="min-h-screen bg-[#010001] flex flex-col sm:p-4 font-serif">
      <div className="h-full w-full md:border-2 md:border-[#4B4B4B] flex-1 sm:rounded-xl flex flex-col md:p-4">
        <div className="flex-1 bg-[#151317] h-full w-full sm:rounded-xl flex flex-col relative  py-4 sm:py-0">
          <img
            src={util1}
            className="absolute left-[65%] animate-pulse lg:block hidden"
            alt=""
          />
          <img
            src={util2}
            className="absolute left-[95%] top-1/4  animate-pulse lg:block hidden"
            alt=""
          />
          <img
            src={util4}
            className="absolute left-[2%] top-[80%] w-20  animate-pulse lg:block hidden"
            alt=""
          />
          <img
            src={util3}
            className="absolute left-[80%] top-[80%]  animate-pulse lg:block hidden"
            alt=""
          />
          <div className="absolute top-1/2 w-full  -translate-y-1/2  flex items-center justify-between">
            <button
              className={`${
                currentAcctive === 0 && "pointer-events-none opacity-50"
              } text-2xl translate-x-4 md:translate-x-20`}
              onClick={decrement}
            >
              <BsArrowLeftSquare />
            </button>
            <button
              className={`${
                currentAcctive === list.length - 1 &&
                "pointer-events-none opacity-50"
              } text-2xl -translate-x-4 md:-translate-x-20`}
              onClick={increment}
            >
              <BsArrowRightSquare />
            </button>
          </div>
          <div className="flex justify-between items-center px-6 py-3">
            <img src={Logo} alt="" className="w-[120px] md:w-[200px]" />
            <Link
              to="/app"
              onClick={(e) => {
                if (difference > 0) {
                  e.preventDefault();
                  setOpen(true);
                }
              }}
              className="gradient-primary py-2 px-8 rounded-full text-xs md:text-base"
            >
              Launch App
            </Link>
          </div>
          <div className="grid md:grid-cols-2 gap-16 max-w-[1000px] w-full mx-auto flex-1 items-center md:p-0 p-3">
            <AnimatePresence>{pages[currentAcctive]}</AnimatePresence>
          </div>
        </div>
        <div className=" max-w-[1000px] hidden md:flex items-center justify-between w-full mx-auto">
          {list.map((val, i) => (
            <button
              key={i}
              className="block relative px-3 py-2 pt-4 "
              onClick={() => setCurrentActive(i)}
            >
              <AnimatePresence>
                {currentAcctive === i && (
                  <motion.img
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 18 }}
                    exit={{ opacity: 0, height: 0 }}
                    className="absolute bottom-full left-1/2 -translate-x-1/2 "
                    src={util}
                  />
                )}
              </AnimatePresence>
              <div
                className={`relative z-10 ${
                  currentAcctive !== i &&
                  "text-[#A7A7A7] transition-colors duration-300"
                } `}
              >
                {val}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
