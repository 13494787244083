import dayjs from "dayjs";
import toast from "react-hot-toast";

export const DEFAULT_REF = "0x395adA49d8F9CF72478fe83E3C4b4661bE9EB7d2";
export const copyToClipBoard = async (copyMe) => {
  if (!copyMe) {
    return;
  }
  try {
    await navigator.clipboard.writeText(copyMe);
    toast.success("Copied to Clipboard !");
  } catch (err) {
    toast.error("copy failed");
  }
};

export const getCurrentUnix = () => {
  const unix = dayjs().utc().unix();
  return unix;
};
