import React, { useContext, useState } from "react";

import { HiMenuAlt3 } from "react-icons/hi";
import { useWeb3React } from "@web3-react/core";
import { shortAddress } from "../web3/helpers";
import Button from "./Button";
import { logo, wallet, copy, link } from "../constants/Images";
import { copyToClipBoard } from "../utils";
import useStakeDetails from "../hooks/useStakeDetails";
import { contractAddress } from "../web3/contracts/primary";
import { WalletModalContext } from "../context/walletModalContext";
import { FaTelegramPlane } from "react-icons/fa";
import toast from "react-hot-toast";

const Header = () => {
  const [active, setActive] = useState(false);
  const { connectHandler } = useContext(WalletModalContext);
  const { account } = useWeb3React();
  const { refrralLink } = useStakeDetails();
  return (
    <div>
      <div className="container py-8 relative z-10">
        <div className=" flex  justify-between items-center rounded-lg">
          <div className=" max-w-[200px] py-2 ">
            <img src={logo} alt="" />
          </div>

          <div className=" hidden md:grid grid-flow-col gap-4">
            <Button
              color="black"
              link
              href="https://t.me/Safehavens_official"
              target="_blank"
            >
              <p>Telegram</p>
              <span>
                <FaTelegramPlane />
              </span>
            </Button>
            <Button
              color="black"
              onClick={() => {
                account
                  ? copyToClipBoard(refrralLink)
                  : toast.error("Connect Wallet");
              }}
            >
              <p>Referral Link</p>
              <img src={copy} className="w-5" alt="" />
            </Button>
            <Button
              color="black"
              link
              href={`https://bscscan.com/address/${contractAddress}`}
              target="_blank"
            >
              <p>Contract</p>
              <img src={link} className="w-5" alt="" />
            </Button>
            <Button onClick={connectHandler}>
              <p>{account ? shortAddress(account) : "Connect Wallet"}</p>
              <img src={wallet} className="w-5" alt="" />
            </Button>
          </div>

          <button
            className="md:hidden text-2xl"
            onClick={() => setActive((prev) => !prev)}
          >
            <HiMenuAlt3 />
          </button>
          <div
            className={`md:hidden fixed top-[130px] overflow-hidden left-[2%] w-[96%] transition-all duration-500 bg-[#1C1C1C] rounded-lg ${
              active ? "max-h-[100vh]" : "max-h-[0]"
            } `}
          >
            <div className="py-10">
              <div className=" mx-auto grid gap-4 justify-center">
                <Button
                  color="black"
                  link
                  href="https://t.me/Safehavens_official"
                  target="_blank"
                >
                  <p>Telegram</p>
                </Button>
                <Button
                  color="black"
                  onClick={() => copyToClipBoard(refrralLink)}
                >
                  <p>Referral Link</p>
                  <img src={copy} className="w-5" alt="" />
                </Button>
                <Button
                  color="black"
                  link
                  href={`https://bscscan.com/address/${account}`}
                  target="_blank"
                >
                  <p>Contract</p>
                  <img src={link} className="w-5" alt="" />
                </Button>
                <Button onClick={connectHandler}>
                  <p>{account ? shortAddress(account) : "Connect Wallet"}</p>
                  <img src={wallet} className="w-5" alt="" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
