import React from "react";
import { motion } from "framer-motion";
import Image1 from "../../../assets/about/4.png";
const Fourth = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <img src={Image1} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <>
          <h2 className="text-3xl">The Answer</h2>
          <p className="text-xl">A Safe and recession-proof investment plan</p>
          <p className="text-sm text-[#ADADAD] mt-4">
            Safe Haven has created a suitable solution to handle the situation.
            We have carefully curated a basket of recession-proof investment
            options that includes{" "}
            <span className="text-primary">
              Gold, Silver, Crude Oil, US Dollar, Selective Crypto Assets and
              Selective stocks that have historically shown resistance to
              recession.
            </span>
          </p>
          <p className="text-sm text-[#ADADAD] mt-4">
            By making calculated investments at the right proportions in the
            above-mentioned options we can keep user funds relatively safe and
            generate stable returns.
          </p>
        </>
      </motion.div>
    </>
  );
};

export default Fourth;
