import React from "react";
import Button from "../../../components/Button";
import { copy } from "../../../constants/Images";
import useStakeDetails from "../../../hooks/useStakeDetails";
import { copyToClipBoard } from "../../../utils";
import { motion } from "framer-motion";
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import { refWithdraw } from "../../../web3/helpers";
const Referral = () => {
  const { refReward, refrralLink } = useStakeDetails();
  const { account, library } = useWeb3React();
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.1, duration: 0.5 }}
      className="card"
    >
      <div className="flex justify-between ">
        <p className="text-[32px] font-semibold">Referral</p>
        <div>
          <p className="text-right text-xl font-medium">{refReward} BUSD</p>
          <p className="text-sm text-[#888682] text-right">
            {" "}
            Total referral earnings
          </p>
        </div>
      </div>
      <p className="text-lg max-w-[500px] text-muted  leading-snug my-4">
        You can earn additional income by inviting people to SafeHaven and
        proceeds from your referral activity can be found here
      </p>
      <br />
      <div className="grid md:grid-flow-col gap-2 justify-start">
        <Button
          color="primary"
          onClick={() => {
            if (!account) return toast.error("Connect Wallet");
            if (refReward <= 0)
              return toast.error("You dont have any rewards to claim");
            refWithdraw(library);
          }}
        >
          <p> Claim Referral</p>
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            if (!account) return toast.error("Connect Wallet");
            copyToClipBoard(refrralLink);
          }}
        >
          <p>Referral</p>
          <img src={copy} alt="" />
        </Button>
      </div>
      <p className="text-muted mt-5">
        Note: Share this referral link with your friends and family and ask them
        to join Safe Haven.
      </p>
    </motion.div>
  );
};

export default Referral;
