import { useWeb3React } from "@web3-react/core";
// import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { contractAddress } from "../web3/contracts/primary";
import { busdAddress } from "../web3/contracts/busd";
import { formatFromWei, getContract } from "../web3/helpers";
import { rpAddress } from "../web3/contracts/rp";

const useStakeDetails = () => {
  const { library, account } = useWeb3React();
  const [investments, setInvesments] = useState(0);
  const [allowance, setAllowance] = useState(false);
  const [rpBalance, setRpBalance] = useState(0);
  const [earnings, setEarnings] = useState(0);
  const [refReward, setRefReward] = useState(0);
  const [weeklyTime, setWeeklyTime] = useState(0);
  const [roi, setRoi] = useState(1);
  const [startClaimTime, setStartClaimTime] = useState(0);
  const [endClaimTime, setEndClaimTime] = useState(0);
  const [userRewards, setUserRewards] = useState(0);
  const [approvedWithdrawal, setApprovedWithdrawal] = useState(0);
  useEffect(() => {
    const getData = async () => {
      if (account) {
        const { ok, contract } = await getContract(library);

        if (!ok) {
          return;
        }
        try {
          let _investments = await contract.methods.investments(account).call();
          _investments = _investments[1];
          setInvesments(formatFromWei(_investments));

          let _ref = await contract.methods.referral(account).call();
          _ref = _ref[1];
          setRefReward(formatFromWei(_ref));

          let _weeklyTime = await contract.methods.weekly(account).call();
          setWeeklyTime(_weeklyTime);

          let _roi = await contract.methods.roi().call();
          setRoi(_roi / 1000);

          let _claimTime = await contract.methods.claimTime(account).call();
          setStartClaimTime(_claimTime[1]);
          setEndClaimTime(_claimTime[2]);

          let _userRewards = await contract.methods.userReward(account).call();
          _userRewards = formatFromWei(_userRewards);
          setUserRewards(Number(_userRewards).toFixed(4));

          let _approvedWithdrawal = await contract.methods
            .approvedWithdrawal(account)
            .call();
          _approvedWithdrawal = formatFromWei(_approvedWithdrawal.amount);

          // console.log(_approvedWithdrawal);
          setApprovedWithdrawal(
            _approvedWithdrawal > 0 ? Number(_approvedWithdrawal).toFixed(2) : 0
          );
        } catch (error) {
          console.log(error);
        }
      }
    };
    getData();
  }, [account, library]);

  useEffect(() => {
    const getData = async () => {
      if (account) {
        const { ok, contract } = await getContract(library);
        const { ok: busdOk, contract: busdContract } = await getContract(
          library,
          busdAddress
        );
        const { ok: rpOk, contract: rpContract } = await getContract(
          library,
          rpAddress
        );

        if (!ok || !busdOk || !rpOk) {
          return;
        }
        try {
          let _allowance = await busdContract.methods
            .allowance(account, contractAddress)
            .call();
          setAllowance(Number(_allowance) > 0);

          let _earnings = await contract.methods.userReward(account).call();
          setEarnings(Number(formatFromWei(_earnings)).toFixed(3));

          let _rpBalance = await rpContract.methods.balanceOf(account).call();
          setRpBalance(formatFromWei(_rpBalance));
          // console.log(formatFromWei(_rpBalance));
        } catch (error) {
          console.log(error);
        }
      }
    };
    const intervalId = setInterval(() => {
      getData();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [account, library]);

  const refrralLink = `${window.location.origin}/app/${account}`;

  return {
    investments,
    allowance,
    rpBalance,
    earnings,
    refReward,
    refrralLink,
    weeklyTime,
    roi,
    startClaimTime,
    endClaimTime,
    userRewards,
    approvedWithdrawal,
  };
};

export default useStakeDetails;
