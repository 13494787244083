/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useTimer from "../hooks/useTimer";
import { zeroPad } from "react-countdown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 99999999,
  width: "90%",
};

export default function TimerModal({ open = true, setOpen }) {
  const { days, hours, seconds, minutes, difference } = useTimer();
  const string = "safe-heaven-loadmodal";

  const handleClose = () => {
    sessionStorage.setItem(string, false);
    setOpen(false);
  };

  React.useEffect(() => {
    const modalState = sessionStorage.getItem(string);
    if (difference > 0) {
      if (modalState === null || undefined) {
        sessionStorage.setItem(string, true);
        setOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difference]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box sx={style}>
          <div className="text-center py-10 px-6 max-w-[450px] rounded-3xl w-full mx-auto bg-white">
            <div className="text-lg mt-2 font-medium">
              {difference > 0 ? (
                <>
                  <h2 className="font-bold text-sm md:text-2xl mb-4">
                    Launching In
                  </h2>
                  <span className="">
                    {days ? `${days} days` : ""} {zeroPad(hours)} hours{" "}
                    {zeroPad(minutes)} minutes {zeroPad(seconds)} seconds{" "}
                  </span>
                </>
              ) : (
                <span>App Is Live Now</span>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
