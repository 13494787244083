import React from "react";
import { motion } from "framer-motion";
import Image1 from "../../../assets/about/1.png";
const First = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <img src={Image1} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <>
          <h2 className="text-3xl">The Warnings</h2>
          <p className="text-xl">A recession is finally upon us</p>
          <p className="text-sm text-[#ADADAD] mt-4">
            Considering the current global economic conditions and the warnings
            of unavoidable hard recession from various central banks around the
            world including US Federal Reserve, ECB (European Central Bank) and
            BOE (Bank of England).
          </p>
          <p className="text-sm text-[#ADADAD] mt-4">
            Not just Federal Banks, prominent economists, CEOs, Bankers and
            former Fed officials are among the people mentioning the downturn.
            According to recent Forbes survey, over 75% of chief executives at
            the Fortune 500 organizations have confirmed that economic growth
            will go negative before 2023 ends.
          </p>
        </>
      </motion.div>
    </>
  );
};

export default First;
