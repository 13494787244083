import React from "react";
import { motion } from "framer-motion";
import Image1 from "../../../assets/about/6.png";

import { Link } from "react-router-dom";
import useTimer from "../../../hooks/useTimer";
const Sixth = ({ setOpen }) => {
  const { difference } = useTimer();
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <img src={Image1} alt="" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5 }}
      >
        <>
          <h2 className="text-3xl">The Numbers</h2>
          <p className="text-xl">Keeping it real and straight</p>
          <div className="text-xs">
            <p className=" mt-4 text-primary">
              The minimum investment to engage with the smart contract is 50
              BUSD and for which every user will get a variable return of 0.25%
              to 1% per day.
            </p>
            <p className=" text-[#ADADAD] mt-4">
              You can also earn additional income by referring your friends and
              family to the smart contract. For each successful investment you
              will get 5% of the investment amount as a referral bonus
            </p>
            <br />
            <Link
              to="/app"
              onClick={(e) => {
                if (difference > 0) {
                  e.preventDefault();
                  setOpen(true);
                }
              }}
              className="gradient-primary py-2 px-8 rounded-full text-xs md:text-base"
            >
              Launch App
            </Link>
          </div>
        </>
      </motion.div>
    </>
  );
};

export default Sixth;
